'use strict';

class LoginController {
  constructor(Auth, $state) {
    this.user = {};
    this.errors = {};
    this.submitted = false;

    this.Auth = Auth;
    this.$state = $state;
  }

  login(form) {
    this.submitted = true;

    if (form.$valid) {
      this.Auth.login({
        email: this.user.email,
        password: this.user.password
      })
      .then(() => {
        // Logged in, redirect to home
        this.$state.go('main');
      })
      .catch(err => {
        switch(err.message) {
          case 'Missing credentials':
            this.errors.other = 'Inserire email e password per procedere';
            break;
          case 'This email is not registered.':
          case 'This password is not correct.':
            this.errors.other = 'Email e/o password non corretti';
            break;
          case 'No valid key found for user':
            this.errors.other = 'Licenza scaduta';
            break;
          default:
            this.errors.other = err.message;
        }
      });
    }
  }
}

angular.module('appApp')
  .controller('LoginController', LoginController);
